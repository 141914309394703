
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "personal-liability-house-insurance",
  components: {},
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Personal Liability", [
        "Householders Insurance "
      ]);
    });
  },
  created() {
    //Set page title
    document.title =
      "Personal Liability Householders Insurance  | " +
      process.env.VUE_APP_TITLE;
  }
});
